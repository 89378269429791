import React, { useState } from "react";
import { UniversalLink } from "./global";
import { graphql, useStaticQuery } from "gatsby";
import { css } from "@emotion/core";

export const headerHeight = "100px";

const Header = () => {
  const [isOpen, setOpen] = useState(false);

  const { logo } = useStaticQuery(graphql`
    query HeaderQuery {
      logo: file(relativePath: { eq: "logo.png" }) {
        childImageSharp {
          fixed(width: 100) {
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      }
    }
  `);

  return (
    <header
      className={
        "w-full flex items-center justify-center p-2 fixed top-0 left-0 bg-gradient text-white antialiased shadow-lg text-xl"
      }
      css={css`
        height: ${headerHeight};
        z-index: 9999;
      `}
    >
      <LinkContainer left>
        <HeaderLink url={"/sponsors"}>Sponsors</HeaderLink>
        <HeaderLink url={"/contact"}>Contact</HeaderLink>
      </LinkContainer>
      <HeaderLink url={"/"}>
        <img
          src={logo.childImageSharp.fixed.src}
          alt={"I Heart BD Logo"}
          className={`block mx-auto px-2`}
          css={css`
            width: 75px;
          `}
        />
      </HeaderLink>
      <LinkContainer right>
        <HeaderLink url={"/gallery"}>Gallery</HeaderLink>
        <HeaderLink url={"/testimonials"}>Testimonials</HeaderLink>
        <HeaderLink url={"/stories"}>Stories</HeaderLink>
      </LinkContainer>
      <MenuIcon
        className={
          "p-3 inline-block md:hidden mr-4 ml-auto cursor-pointer text-white"
        }
        onClick={() => setOpen(true)}
      />
      <FullScreenMenu isOpen={isOpen} setOpen={setOpen} />
    </header>
  );
};

interface HeaderLinkProps {
  url: string;
  [key: string]: any;
}
const HeaderLink = ({ className, url, ...rest }: HeaderLinkProps) => {
  return (
    <UniversalLink
      {...rest}
      url={url}
      className={`${
        className ? className : ""
      } inline-block no-underline font-bold md:px-2 lg:px-4`}
    />
  );
};

interface LinkContainerProps {
  children: React.ReactNode | React.ReactNodeArray;
  left?: boolean;
  right?: boolean;
}
const LinkContainer = ({ children, left, right }: LinkContainerProps) => {
  return (
    <div
      className={`hidden md:flex w-2/5 ${
        left
          ? "justify-end lg:pr-6"
          : right
          ? "justify-start lg:pl-6"
          : "initial"
      }`}
    >
      {children}
    </div>
  );
};

interface FullScreenMenuProps {
  isOpen: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const FullScreenMenu = ({ isOpen, setOpen }: FullScreenMenuProps) => {
  return (
    <div
      className={`${
        isOpen ? "flex" : "hidden"
      } flex-col items-center h-full w-full fixed top-0 left-0 bg-white text-primary`}
      css={css`
        z-index: 99999;
      `}
    >
      <div
        className={"flex items-center p-2 justify-end w-full"}
        css={css`
          height: ${headerHeight};
        `}
      >
        <CloseIcon
          onClick={() => setOpen(false)}
          className={`mr-4 p-3 cursor-pointer text-primary`}
        />
      </div>
      <div
        className={
          "text-2xl space-y-4 w-full flex flex-col justify-center items-center flex-grow pb-16"
        }
      >
        <HeaderLink url={"/sponsors"} onClick={() => setOpen(false)}>
          Sponsors
        </HeaderLink>
        <HeaderLink url={"/contact"} onClick={() => setOpen(false)}>
          Contact
        </HeaderLink>
        <HeaderLink url={"/gallery"} onClick={() => setOpen(false)}>
          Gallery
        </HeaderLink>
        <HeaderLink url={"/testimonials"} onClick={() => setOpen(false)}>
          Testimonials
        </HeaderLink>{" "}
        <HeaderLink url={"/stories"} onClick={() => setOpen(false)}>
          Stories
        </HeaderLink>
      </div>
    </div>
  );
};

interface CloseIconProps {
  onClick: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  className: string;
}
const CloseIcon = ({ onClick, className }: CloseIconProps) => (
  <button onClick={onClick} aria-label="Main menu close" className={className}>
    <svg
      viewBox="0 0 20 20"
      xmlns="http://www.w3.org/2000/svg"
      className={"w-4 h-4"}
    >
      <path
        d="M10 8.586L2.929 1.515 1.515 2.929 8.586 10l-7.071 7.071 1.414 1.414L10 11.414l7.071 7.071 1.414-1.414L11.414 10l7.071-7.071-1.414-1.414L10 8.586z"
        fill="currentColor"
        fillRule="evenodd"
      />
    </svg>
  </button>
);

interface MenuIconProps {
  onClick:
    | ((event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void)
    | undefined;
  className: string;
}
const MenuIcon = ({ onClick, className }: MenuIconProps) => (
  <button onClick={onClick} aria-label="Main menu open" className={className}>
    <svg
      viewBox="0 0 20 20"
      xmlns="http://www.w3.org/2000/svg"
      className={"w-4 h-4"}
    >
      <path
        d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z"
        fill="currentColor"
        fillRule="evenodd"
      />
    </svg>
  </button>
);

export default Header;
