import * as React from "react";
import { Link as GatsbyLink } from "gatsby";
import { OutboundLink } from "gatsby-plugin-google-analytics";
import { AnchorLink } from "gatsby-plugin-anchor-links";

export interface UniversalLinkProps {
  url: string;
  className?: string;
  [key: string]: any;
}

export const UniversalLink = React.forwardRef(
  ({ url, className, ...rest }: UniversalLinkProps, ref) => {
    if (url.includes(":"))
      // this covers http(s):// AND mailto: or tel: ... note: anything specifiying a port (:3000) would be external anyway(?) so this should work(?)
      return (
        <ExternalLink href={url} className={className} ref={ref} {...rest} />
      );
    else if (url.startsWith("/")) {
      if (url.includes("#")) {
        // if url includes a hash, we instead use the gatsby-plugin-anchor-links library to smooth scroll to it
        return (
          // TODO: giving ref prop gives error
          <AnchorLink to={url} className={className} {...rest} />
        );
      } else {
        return (
          <InternalLink to={url} className={className} ref={ref} {...rest} />
        );
      }
    } else {
      console.error(
        "Prop 'url' in component 'A' (link component) did not include a ':' or start with '/'. Please format the URL properly so we can set it up as either an internal or external link."
      );
      // TODO: can I not give 'ref' prop to regular <a> element? Gives me an error.
      // eslint-disable-next-line jsx-a11y/anchor-has-content
      return <a href={url} className={className} {...rest} />;
    }
  }
);

interface InternalLinkProps {
  to: string;
  [key: string]: any;
}

const InternalLink = ({ to, className, ...rest }: InternalLinkProps) => (
  <GatsbyLink
    to={to}
    activeClassName="active"
    className={className}
    {...rest}
  />
);

type ExternalLinkProps = {
  href: string;
  [key: string]: any;
};
const ExternalLink = ({ href, className, ...rest }: ExternalLinkProps) => (
  <OutboundLink href={href} className={className} {...rest} />
);
