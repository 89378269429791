import React from "react";
import Header from "./Header";
import SocialIcons from "./SocialIcons";

import { headerHeight } from "./Header";
import { css } from "@emotion/core";

interface LayoutProps {
  children: React.ReactNode | React.ReactNodeArray;
}

const Layout = ({ children }: LayoutProps) => {
  return (
    <div
      className="flex flex-col min-h-screen"
      css={css`
        padding-top: ${headerHeight};
      `}
    >
      <Header />
      <main className="mx-auto w-full flex-grow">{children}</main>

      <footer className="bg-cool-gray-900">
        <SocialIcons
          bgTransparent
          containerClassName={"flex justify-center space-x-2 mt-4 md:mt-8"}
          iconClassName={"rounded-lg"}
        />
        <nav className="flex justify-between max-w-4xl px-4 pb-8 md:p-8 mx-auto text-sm">
          <p className="font-bold text-white no-underline antialiased">
            &copy; {new Date().getFullYear()} I ❤️ BD
          </p>
          <p className="text-white antialiased">
            Created by{" "}
            <a
              className="font-bold no-underline border-b border-accent antialiased"
              href="https://wemaketechsimple.com"
              target="_blank"
              rel="noopener noreferrer"
            >
              InterQuest
            </a>
          </p>
        </nav>
      </footer>
    </div>
  );
};

export default Layout;
